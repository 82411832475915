const PrivacyPolicy = () => {
    return (
        <>
            <div className="container-fluid page-header" data-wow-delay="0.1s">
                <div className="container text-center">
                    <h1 className="display-4 text-white animated slideInDown mb-4">Privacy Policy</h1>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <br />
                        <br />
                        <div className='box_layout_shadow'>
                            <div className="point_heading_main"><span>1.</span> COLLECTION OF PERSONAL DATA AND PROCESSING PURPOSES</div>
                            <p>please bear in mind that before using any of our services or functionalities, you must read this Privacy and Cookies Policy as well as the Terms and Conditions in the specific section regarding the service or functionality. In their section you can see if there are any specific conditions for use, or if they require specific processing of your Personal Data. The user (you) may choose not to provide certain Personal Data or withdraw the consent subsequently with respect to such Personal Data by sending a written intimation. However, not providing certain compulsory information or withdrawing the consent may mean that it will not be possible to manage your registration as a user or to use certain functionalities or services available through the Platform.</p>
                            <p>You hereby guarantee that the Personal Data provided is true and exact, and agrees to report any change or modification thereto. If you provide us with the Personal Data of third parties, you shall be responsible for having informed them and obtained their consent for these details to be provided for the purposes indicated in the corresponding sections of this Privacy and Cookies Policy. Any loss or damage caused to the Platform, to Data Controllers or to any third party through the communication of erroneous, inaccurate or incomplete information on the registration forms shall be the sole responsibility of the user. The Data Controllers, shall use Personal Data for the following purposes:</p>
                            <p><span>1.1</span> to manage your registration as a user of the Platform. The Personal Data you provide us with shall be used to identify you as a Platform user, and to give you access to its different functionalities, products and services that are available to you as a registered user.</p>
                            <p><span>1.2</span> The development, compliance and undertaking of the purchase contract for the products you have acquired or any other contract with us through the Platform. In particular, you must bear the following in mind: </p>
                            <ol type="I">
                                <li>If you choose the option to save your card, you shall expressly authorize us to process the indicated details to be used as necessary for its activation and development. The card's security code (CVV or CVC) shall only be used for making the purchase and shall not be subsequently stored or processed as part of the payment details. Consenting to the activation of this functionality means that your data will automatically appear in these fields when making future purchases, so you will not have to enter your details again for each new purchase as they will be considered as valid and in place. You can change or delete your cards at any time through the My Account section. We store and transmit your card details in accordance with the leading international standards of confidentiality and security for credit and debit cards. The use of this function may require you to change your access password for security reasons. Remember that security when using the Platform also depends on the correct use and storage of certain confidential codes.</li>
                                <li>In the event that you have purchased a Gift Card, if you provide us with the personal data of third parties, you shall be responsible for having informed them and obtained their consent so that, in compliance with the Platform’s Use and Purchase Conditions, we will be able to process their data for purposes of (a) managing the shipping or verifying receipt of the Gift Card, and (b) attending to any requests that you or said third party may make.</li>
                            </ol>
                            <div className="point_heading"><span>1.3</span> Contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as the App's push notifications etc., regarding updates or informative communications related to the functionalities, products or contracted services, including Platform security updates, when necessary or reasonable for their implementation.</div>
                            <div className="point_heading"><span>1.4</span> Attend and manage your requests made using the available customer service channels in relation to the Platform or the Physical Stores, as well as monitoring the quality of our service.</div>
                            <div className="point_heading"><span>1.5</span> Personalise the services we offer you and enable us to give you recommendations based on your interactions with Hawklux Team on the Platform and an analysis of your user profile (for example, based on your purchase and browsing history). Likewise, show you ads on the Internet which you may see when visiting websites and apps, for example, on social media. The ads you see may be random, but on other occasions they may be ads related to your preferences or purchase and browsing history.</div>
                            <ol type="I">
                                <li>If you use social media, we may provide the companies with which we collaborate certain information so that they can show you Hawklux ads and, in general, offer you and users like you advertisements which take into account your profile on said social media sites. If you want information about the use of your data and how advertising works on social media, we recommend you review the privacy policies of the social media sites on which you have profiles.</li>
                                <li>We also use your data to carry out measurement and segment analyses on the ads which we show users on some of our collaborators’ platforms. To do this we collaborate with these third parties who offer us the necessary technology (for example, cookies, pixels, SDK) to use these services. Keep in mind that, although we do not provide identifying personal data to these collaborators, we do give them some form of identifier each time (for example, the advertising ID associated with the device, an identifier associated with a cookie, etc.) If you would like more information in this respect, please review our Cookies Policy. Likewise, you can reset your advertising ID or disable personalised ads on your device (if you have our App installed on an iOS device you can adjust your preferences in Settings/Privacy/Ads. If your device is Android, access Settings/Google/Ads).
                                </li>
                            </ol>
                            <div className="point_heading"><span>1.6</span> Data enrichment: When we gather your personal data from a variety of sources, we may consolidate them under certain circumstances for the purpose of improving our understanding of your needs and preferences related to our products and services (including for the purposes of analyses, generating user profiles, marketing studies, quality surveys and improving our interactions with our customers). This refers, for example, to the way we may combine your information if you have a registered account and, using the same email linked to your account, you make a purchase as a guest, or to information which is automatically compiled (such as IP and MAC addresses or metadata) which we may link with the information you have provided us directly through your activity on the Platform or in any of our stores (for example, information related to your purchases, whether in brick and mortar stores or online, your preferences, etc.)</div>
                            <br />

                            <div className="point_heading_main"><span>2.</span> NEWSLETTER SUBSCRIPTION</div>
                            <p>If you authorize your subscription to the Hawklux Newsletter, We will provide you with information regarding our products and services, through different means as, among others, by email, any other equivalent electronic means of communication (such as SMS, etc.) as well as through push notifications via the App – in case you have activated such notifications in your mobile device. Subscription to the Hawklux Newsletter may entail using Personal Data to make personalized advertising related to our products and services available to you via email, SMS or any other of our own electronic media or those of third parties. We can also provide you with this information through push notifications from the App in case you have activated them in your mobile device. With the aim of improving the service we provide you with, we hereby inform you that Personal Data related to your purchases at online or physical stores, tastes and preferences, may be used for the purpose of analysis, generating user profiles, marketing studies, quality surveys and improving our client interaction. If you are a registered user, you can change your preferences related to the sending of these commercial communications through the My Account section. You can also unsubscribe through the Platform's Newsletter section at any time, or by following the information we provide in each communication.</p>
                            <div className="point_heading_main"><span>3.</span> OTHER NECESSARY USES OF YOUR PERSONAL DATA</div>
                            <p>It may also be necessary to transfer your Personal Data to the mentioned companies belonging to the Hawklux or to third parties who provide us with support services, such as financial bodies, entities that fight against fraud, providers of technological, analytical, logistical, transport and delivery services (and/or their partner establishments), service providers and collaborators related to marketing and publicity, such as social media sites, advertising agencies or advertising partners, customer services and/or services that analyse the transactions made on the Platform with the aim of offering users sufficient guarantees in commercial operations, etc. By providing us with your Personal Data on this Platform, you are authorizing us to process and share your information with said collaborators. Your authorisation includes those cases where, for purposes of service efficiency. The providers may be located in the European Union or in the United States of America or other countries or territories situated outside of the European Economic Area. Such providers will provide the same level of security measures for data protection as provided under applicable Indian laws.</p>
                            <p>We may disclose your Personal Data to the Government agencies if required, even without obtaining your prior consent, for the purposes of verification of identity or for prevention, detection, investigation including cyber incidents, prosecution and punishment of offences or for other purposes required under applicable law.</p>
                            <div className="point_heading_main"><span>4.</span> SECURITY PRACTICES</div>
                            <p>Hawklux India Private Limited adopts reasonable security practices and procedures as mandated under applicable Indian laws and the companies belonging to Hawklux adopt similar security practices and procedures for the protection of Personal Data and to prevent unauthorised use or disclosure of Personal Data.</p>
                            <div className="point_heading_main"><span>5.</span> INFORMATION ON COOKIES</div>
                            <p>we use cookies in this Platform, which are small text files with information on your navigation in this Platform, whose principal objective is to improve your experience in the Platform. Please, continue reading, to find further information on the cookies that we use, their purpose and other information of interest.</p>
                            <div className="point_heading_main"><span>6.</span> CONTACT INFORMATION</div>
                            <p>In case of any query, you may contact us via contact form or Email us at info@hawklux.com your collection is already set up for you with fields and content. Add your own, or import content from a CSV file. Add fields for any type of content you want to display, such as rich text, images, videos and more. You can also collect and store information from your site visitors using input elements like custom forms and fields.</p>
                            <p>Be sure to click Sync after making changes in a collection, so visitors can see your newest content on your live site. Preview your site to check that all your elements are displaying content from the right collection fields.</p>
                        </div>
                        <br />
                        <br />

                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy;