import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Header from './component/Header';
import Home from './Home';
import About from './component/About';
import Contact from './component/Contact';
import NotFound from './component/NotFound';
import Faq from './component/Faq';
import Footer from './component/Footer';
import Products from './component/Products/Product';
import Items from './component/Products/Items';
import HospitalBeds from './component/Products/HospitalBeds';
import OtherFurnitures from './component/Products/OtherFurnitures';
import OTTables from './component/Products/OTTables';
import OTLights from './component/Products/OTLights';
import EndoscopyCamera from './component/Products/EndoscopyCamera';
import LEDLightSource from './component/Products/LEDLightSource';
import CO2Insufflator from './component/Products/CO2Insufflator';
import Colposcope from './component/Products/Colposcope';
import Hysteroscopy from './component/Products/Hysteroscopy';
import OtIcuEquipments from './component/Products/OtIcuEquipments';
import ProductItems from './ProductItems.json';
import EndscopyFullHdCamera from './newLunchProducts/EndscopyFullHdCamera';
import PrivacyPolicy from './component/PrivacyPolicy';
import TermsConditions from './component/TermsConditions';
import ReturnPolicy from './component/ReturnPolicy';
import HandInstruments from './component/Products/HandInstruments';
import Urology from './component/Products/Urology';
import CapitalEquipment from './component/Products/CapitalEquipment';
import Conference from './component/Conferece';
import Laparoscopy from './component/Products/Laparoscopy';
import Gynecology from './component/Products/Gynecology';
import Ent from './component/Products/Ent';
import General from './component/Products/General';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Header />
        <Outlet />
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/conference" element={<Conference />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/faq" element={<Faq />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/terms-conditions" element={<TermsConditions />}></Route>
            <Route path="/return-policy" element={<ReturnPolicy />}></Route>
            <Route path="/products" element={<Products />}></Route>
            <Route path="/ot-icu-equipments" element={<OtIcuEquipments />}></Route>
            <Route path="/products/hospital-beds" element={<HospitalBeds />}></Route>
            <Route path="/products/other-furnitures" element={<OtherFurnitures />}></Route>
            <Route path="/products/ot-tables" element={<OTTables />}></Route>
            <Route path="/products/ot-lights" element={<OTLights />}></Route>
            <Route path="/products/endoscopy-Camera" element={<EndoscopyCamera />}></Route>
            <Route path="/products/led-light-source" element={<LEDLightSource />}></Route>
            <Route path="/products/co2-insufflator" element={<CO2Insufflator />}></Route>
            <Route path="/products/colposcope" element={<Colposcope />}></Route>
            <Route path="/products/hysteroscopy" element={<Hysteroscopy />}></Route>
            <Route path="/products/hand-instruments" element={<HandInstruments />}></Route>
            <Route path="/products/urology" element={<Urology />}></Route>
            <Route path="/products/capital-equipment" element={<CapitalEquipment />}></Route>
            <Route path="/products/endscopy-full-hd-camera" element={<EndscopyFullHdCamera />}></Route>
            <Route path="/products/laparoscopy" element={<Laparoscopy />}></Route>
            <Route path="/products/gynecology" element={<Gynecology />}></Route>
            <Route path="/products/ent" element={<Ent />}></Route>
            <Route path="/products/general" element={<General />}></Route>
            {ProductItems.map((item, i) => { return <Route exact path={`/products/:${item.url}/:params`} element={<Items />}></Route> })}
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </ScrollToTop>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
