import { Link, useParams } from "react-router-dom";
import ProductItems from '../../ProductItems.json';

const Items = () => {
    const data = useParams();
    const product_details = ProductItems[data.params - 1];
    const images = [product_details.image];
    console.log("product_details.features_1", product_details.features_1);
    return (
        <>
            <section id="about_section">
                <div className="container-fluid py-5" id="about_wraper_">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6" id="left_sec_">
                                <div className="h-100">
                                    <h1 className="section_title_">{product_details.title}</h1>
                                    <p className="para_">{product_details.description}</p>
                                    {
                                        product_details.url === "led-light-source" ? (
                                            <>
                                                <div className="product_specfi_titile">Our company prides itself on providing several advantages to our customers:</div>
                                                <div className="para_"><b>1. LED bulb:</b> Our LED bulbs are from LUMINUS USA, ensuring the highest quality of light for your camera or endoscope. We do not use LED bulbs made in China or Taiwan, which may be priced lower but do not offer the same level of quality as the LUMINUS bulb.</div>
                                                <div className="para_"><b>2. Quality guarantee:</b> Many other factories may use low-quality accessories to cut costs, which can lead to problems after just 6 to 12 months of use. However, we are using more than 95% of our accessories from reputable sources in the USA and Japan and maintain our product's manufacturing cost by purchasing in bulk. We are confident in the quality of our products and offer a 2-year guarantee for our entire light source. In reality, our products can often last 6 to 8 years without issue.</div>
                                                <p className="para_">Our commitment to providing high-quality products and excellent customer service sets us apart from other manufacturers in the industry.</p>
                                                <div className="product_specfi_titile">The basic parameters of our product:</div>
                                                <div className="para_"><b>1. LED power: </b>Our product boasts a 120W LED bulb, ensuring powerful and reliable illumination.</div>
                                                <div className="para_"><b>2. Input power: </b>Our product's input power is versatile, ranging from 100-240V/AC and 50/60 Hz, allowing it to be used in any country.</div>
                                                <div className="para_"><b>3. Intensity:</b> Our product's intensity exceeds traditional 350W xenon light sources by 40%, and is more than twice as powerful as other LED light sources on the market with the same power.</div>
                                                <div className="para_"><b>4. Color temperature:</b> Our product has a color temperature of 5600K and a CRI of 70 and 90, ensuring accurate color reproduction.</div>
                                                <div className="para_"><b>5. LED life: </b>Our LED bulb is capable of lasting up to 60,000 hours, which is 120 times longer than a typical xenon bulb, significantly reducing the need for bulb replacement.<br />Note: The above life hour is based on ideal lab test conditions.</div>
                                                <div className="para_"><b>6. Connector:</b> Our product's customized connector allows for fibre light guides with active areas ranging from 3 mm to 10 mm in diameter.</div>
                                                <div className="para_"><b>7. No UV or IR emissions:</b>No UV or IR emissions: Our product does not emit light in UV or IR wavelengths.</div>
                                                <div className="para_"><b>8. Energy-saving:</b> Our product is designed to save energy, reducing energy consumption by more than 50%.</div>
                                                <div className="para_"><b>9. Environmentally friendly:</b> Our product is eco-friendly, making it a sustainable choice for your business or practice.</div>
                                                <br />
                                            </>     

                                        ) : product_details.url === "co2-insufflator" ? (
                                            <>
                                                <p className="para_">One of the standout features of the CO2 Advanced Insufflator is its smart software that reduces the flow of CO2 as soon as it senses that it is no longer required by the clinician. This feature can significantly improve intubation depth and patient comfort during procedures such as DBE, EGD, colonoscopy, and others. Additionally, it helps to reduce post-procedural abdominal pain and quicken patient recovery time.</p>
                                                <p className="para_">The insufflator has a compact design and managed flow mode for conserving gas. It does not require specialized CO2 valves, and there is no need for special utilities to operate the device. The device is equipped with an over-pressure alarm and safety shutdown features to help ensure patient safety during use.</p>
                                                <p className="para_">The CO2 Advanced Insufflator is suitable for a range of applications such as ERCP, Enteroscopy, EUS, EGD, and colonoscopy. Its compact design and advanced features make it an excellent choice for medical professionals seeking to improve patient outcomes and provide more efficient procedures.</p>
                                                <p className="para_">The CO2-30L Advance insufflator uses medical-grade CO2 gas and has an input pressure range of 2 to 3 bar. Its managed flow mode helps conserve gas, and it does not require specialized CO2 valves. It features an over-pressure alarm and safety shutdown for patient safety during use. The device is powered by 230 VAC + 15% @ 50-60 Hz and protected by a 5A fuse. It has a maximum power consumption of 125 W with a warmer of 20 W max. The output connection is 6 to 7 mm ID tube. The insufflation pressure ranges from 4 to 30 mm Hg with an accuracy of 1 mm Hg. The CO2 flow rate ranges from 1 to 30 litres/min.</p>
                                                <p className="para_">Overall, The Hawklux 30L Advance CO2 insufflator not only offers superior quality but also comes with a competitive price point as compared to other brands in the market. It is a game-changing device that provides unparalleled performance and reliability, making it a must-have for any medical facility looking to provide high-quality care to its patients during endoscopy procedures.</p>
                                                <p className="para_"></p>
                                            </>
                                        ) : product_details.url === "endscopy-full-hd-camera" ? (
                                            <div>
                                            <p className="para_">The <b>HL-1080-FHD</b> camera is a sophisticated imaging device that is specifically designed to meet the high standards and demands of surgical and medical applications. It goes beyond just delivering high-resolution imaging capabilities by incorporating advanced features such as high dynamic range technology for improved color differentiation and anti-reflection optics for sharper focus. These features are essential for ensuring the best possible visualization during medical procedures.</p>
                                            <p className="para_">Contrary to popular belief, higher megapixels or 4k resolution do not always translate to better image quality. Manufacturers may achieve high megapixel counts at low costs, often at the expense of other important factors. The <b>HL-1080-FHD</b> camera, on the other hand, is built to provide clear and precise imaging with sharp focus and deeper details. Medical professionals can rely on this camera to deliver the highest level of image quality that is essential for making informed decisions during complex surgical or medical procedures.</p>
                                            <p className="para_">In the realm of imaging, the size of the sensor is a crucial factor in determining the quality of the final image. The amount of light utilized in creating an image is largely dependent on the size of the sensor. Consequently, a larger sensor can capture more information than a smaller one, resulting in images with higher details, better dynamic range, and reduced noise.</p>
                                            <p className="para_">Our camera, the <b>HL-1080-FHD,</b> is equipped with the latest Sony 1/1.8" sensor that offers 50% more surface area than the conventional 1/2.8" sensors used in other cameras. These cheaper sensors compromise on image quality, resulting in images with fewer pixels. The use of our camera's larger sensor results in images with 50% more pixels, providing a significant increase in image quality.</p>
                                            <div className="para_">The <b>HL-1080-FHD</b> camera is highly versatile, featuring multiple modes for different types of surgeries. It has the ability to deliver deep, natural, and realistic images, making it suitable for a wide range of applications including laparoscopy, urology, arthroscopy, laryngoscopy, nasal surgery, and ENT procedures.</div>
                                            <div className="para_">The camera's 4k anti-reflection coupler is designed to be compatible with all rigid endoscopes and some Flexible Laryngoscopes and Urethroscopes. With its advanced features and compatibility, the <b>HL-1080-FHD</b> camera is an ideal choice for medical professionals seeking high-quality imaging technology.</div>
                                            <br />
                                        </div>
                                        )
                                        : (
                                            <div className="row g-2 mb-4 pb-2">
                                                {product_details.features_1 === undefined || product_details.features_1 === '' ? '' : (
                                                    <div className="col-sm-12">
                                                        <i className="fa fa-check text-primary me-2"></i> {product_details.features_1}
                                                    </div>
                                                )}
                                                
                                                {product_details.features_2 === undefined || product_details.features_1 === '' ? '' : (
                                                    <div className="col-sm-12">
                                                        <i className="fa fa-check text-primary me-2"></i> {product_details.features_2}
                                                    </div>
                                                )}
                                                {product_details.features_3 === undefined || product_details.features_1 === '' ? '' : (
                                                    <div className="col-sm-12">
                                                        <i className="fa fa-check text-primary me-2"></i> {product_details.features_3}
                                                    </div>
                                                )}
                                               {product_details.features_4 === undefined || product_details.features_1 === '' ? '' : (
                                                    <div className="col-sm-12">
                                                        <i className="fa fa-check text-primary me-2"></i> {product_details.features_4}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }
                                    
                                    {/* <Link className="btn btn-primary py-3 px-5" to="/contact">Contact Us</Link> &nbsp;&nbsp;&nbsp; */}
                                    <Link className="btn btn-primary py-3 px-5" to="/contact">Enquiry Now</Link>
                                </div>
                            </div>
                            <div className="col-lg-6" id="right_sec_">
                                <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                    {
                                        images[0].map((item, i) => {
                                            if (i === 0) {
                                                return (
                                                    <>
                                                        <div className="carousel-item active">
                                                            <img className="w-100 img-thumbnail" src={`../${item}`} alt="banner" />
                                                        </div>
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        <div className="carousel-item">
                                                            <img className="w-100 img-thumbnail" src={`../${item}`} alt="banner" />
                                                        </div>
                                                        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                                                            data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Previous</span>
                                                        </button>
                                                        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                                                            data-bs-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Next</span>
                                                        </button>
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                    </div>
                                    
                                </div>
                                {
                                    product_details.url === "co2-insufflator" ? (
                                        <>
                                            <br />
                                            <div className="prd_descript">
                                                <div className="product_specfi_titile">Specification Details</div>
                                                <p><b>Gas:</b>	CO2 (medical)</p>
                                                <p><b>CO2 gas supply systems:</b>	Inf 7/16"</p>
                                                <p><b>Input pressure range:</b>		2 to 3 bar</p>
                                                <p><b>Insufflation pressure range:</b>	4 to 30 mm Hg (to an accuracy of 1 mm Hg)</p>
                                                <p><b>Managed flow mode:</b>		Yes</p>
                                                <p><b>Specialized CO2 valves:</b>	Not required</p>
                                                <p><b>Over-pressure alarm:</b>		Yes</p>
                                                <p><b>Safety shutdown:</b>			Yes</p>
                                                <p><b>Power supply:</b>			230 VAC + 15% @ 50-60 Hz</p>
                                                <p><b>Fuse:</b>					5A</p>
                                                <p><b>Power consumption:</b>		125 W max with warmer of 20 W max</p>
                                                <p><b>Output connection:</b>		6 to 7 mm ID tube</p>
                                                <p><b>Display:</b>				7-segment 16 x 2 LCD</p>
                                            </div>
                                        </>
                                    ) : product_details.url === "endscopy-full-hd-camera" ? (
                                        <>
                                            <br />
                                            <div className="prd_descript">
                                                <div className="product_specfi_titile">Product Specification:</div>
                                                <p><b>Product Name : </b> Endoscopy Camera</p>
                                                <p><b>PCB Board Material : </b> Double-side copper-clad laminate</p>
                                                <p><b>Resolution : </b> 1920*1080P (Analog 1100 TVL definition, 60FPS frame)</p>
                                                <p><b>Pixels : </b> 2.07M pixels</p>
                                                <p><b>Scanning System : </b> Progressive Scanning</p>
                                                <p><b>Video Output : </b> HDMI/DVI/SDI/CVBS image output port</p>
                                                <p><b>Recording : </b> USB image/video recording supported</p>
                                                <p><b>Video Standard Control : </b> NTSC/PAL</p>
                                                <p><b>Camera Head : </b> Equipped with 4 Programmable buttons with default settings of AWB, Freeze, Recording and Snap.</p>
                                                <p><b>Brightness : </b> Range 0~20, Default: 7</p>
                                                <p><b>AGC : </b> Range 1~10, Default: 10</p>
                                                <p><b>Sharpness : </b> Range 1~10, Default: 7</p>
                                                <p><b>Color Gain : </b> Range 0~20, Default: 10</p>
                                                <p><b>Gama : </b> Range 0.45~0.78, Default: 0.55</p>
                                                
                                            </div>
                                        </>
                                    ) : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Items;