import { useState } from 'react';
import { Link } from 'react-router-dom';
import application_icon_1 from '../../src/img/application_icon_1.png';
import application_icon_2 from '../../src/img/application_icon_2.png';
import application_icon_3 from '../../src/img/application_icon_3.png';
import application_icon_4 from '../../src/img/application_icon_4.png';
import urology_dept from '../../src/img/urology-dept.jpg';
import ent_dept from '../../src/img/ent-dept.jpg';
import gynecology_dept from '../../src/img/gynecology-dept.jpg';
import general_dept from '../../src/img/general-dept.jpg';
import laparoscopy_departmenr from '../../src/img/laparoscopy-departmenr.jpg';

const HomeOurApplication = () => {
    const [tabSec1, setTabSec1] = useState(false);
    const [tabSec2, setTabSec2] = useState(false);
    const [tabSec3, setTabSec3] = useState(false);
    const [tabSec4, setTabSec4] = useState(false);
    const [tabSec5, setTabSec5] = useState(true);
    const tabHandle1 = () => {
        setTabSec1(true); setTabSec2(false); setTabSec3(false); setTabSec4(false); setTabSec5(false);
    }
    const tabHandle2 = () => {
        setTabSec1(false); setTabSec3(false); setTabSec4(false); setTabSec2(true); setTabSec5(false);
    }
    const tabHandle3 = () => {
        setTabSec1(false); setTabSec3(true); setTabSec4(false); setTabSec2(false); setTabSec5(false);
    }
    const tabHandle4 = () => {
        setTabSec1(false); setTabSec3(false); setTabSec4(true); setTabSec2(false); setTabSec5(false);
    }
    const tabHandle5 = () => {
        setTabSec1(false); setTabSec3(false); setTabSec4(false); setTabSec2(false); setTabSec5(true);
    }
    return (
        <>
            <div className="container mt-5">
                <div className="row g-5">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="text-primary text-uppercase mb-2">Our</h6>
                        <h1 className="display-6 mb-4">Applications</h1>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-sm-12">
                        <ul className="department_list_">
                            <li id="department_1" onClick={tabHandle5} className={tabSec5 ? "active" : ''}><img src={application_icon_1} alt="" /> Laparoscopy Dept.</li>
                            <li id="department_1" onClick={tabHandle1} className={tabSec1 ? "active" : ''}><img src={application_icon_1} alt="" /> Urology Dept.</li>
                            <li id="department_3" onClick={tabHandle3} className={tabSec3 ? "active" : ''}><img src={application_icon_3} alt="" /> Gynecology Dept.</li>
                            <li id="department_2" onClick={tabHandle2} className={tabSec2 ? "active" : ''}><img src={application_icon_2} alt="" /> E.N.T Dept.</li>
                            <li id="department_4" onClick={tabHandle4} className={tabSec4 ? "active" : ''}><img src={application_icon_4} alt="" /> General Dept.</li>
                            
                        </ul>
                        <div className={tabSec1 ? "department_img_sec active" : "department_img_sec"} id="department_1_img">
                            <img src={urology_dept} className="img-fluid img-thumbnail" alt="" />
                            <Link to="/products/urology" className="link_btn">Go To Product List</Link>
                            
                        </div>
                        <div className={tabSec2 ? "department_img_sec active" : "department_img_sec"} id="department_2_img">
                            <img src={ent_dept} className="img-fluid img-thumbnail" alt="" />
                            <Link to="/products/ent" className="link_btn">Go To Product List</Link>
                        </div>
                        <div className={tabSec3 ? "department_img_sec active" : "department_img_sec"} id="department_3_img">
                            <img src={gynecology_dept} className="img-fluid img-thumbnail" alt="" />
                            <Link to="/products/gynecology" className="link_btn">Go To Product List</Link>
                        </div>
                        <div className={tabSec4 ? "department_img_sec active" : "department_img_sec"} id="department_4_img">
                            <img src={general_dept} className="img-fluid img-thumbnail" alt="" />
                            <Link to="/products/general" className="link_btn">Go To Product List</Link>
                        </div>
                        <div className={tabSec5 ? "department_img_sec active" : "department_img_sec"} id="department_5_img">
                            <img src={laparoscopy_departmenr} className="img-fluid img-thumbnail" alt="" />
                            <Link to="/products/laparoscopy" className="link_btn">Go To Product List</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeOurApplication;