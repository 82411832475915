import { useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const [successMessage, setSuccessMessage] = useState();
    const sendContactForm = (e) => {
        e.preventDefault();
        console.log("Click");
        emailjs.sendForm('service_usc4zzd', 'template_og9tsto', e.target, 'oJbBqRMUkROA7zc-o').then((res) => {
            console.log(res.text);
            setSuccessMessage("Your query has been submited successfully!!!");
            setTimeout(() => {
                setSuccessMessage(null);
            }, 4000);

        }).catch((error) => {
            console.log(error.text);
        });
    }
    return (
        <>
            <div className="container-fluid page-header" data-wow-delay="0.1s">
                <div className="container text-center">
                    <h1 className="display-4 text-white animated slideInDown mb-4">Contact Us</h1>
                </div>
            </div>


            <div className="container-fluid py-6" id="contact_sec_">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-5">
                            <div className='box_layout_shadow get_in_touch_sec'>
                                <div class="col-sm-12"><h4 class="mb-4">Get In Touch</h4><p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>C-110, Sector 65, Noida, Uttar Pradesh, India</p><p class="mb-2"><i class="fa fa-mobile-alt me-3"></i>+91-9872383819</p><p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+91-1203116842</p><p class="mb-2"><i class="fa fa-envelope me-3"></i>info@hawklux.com</p></div>
                            </div>
                        </div>
                        <div className="col-lg-7 my-6 mb-0 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="bg-primary text-center p-5" id="contact_form_sec">
                                <h1 className="mb-4 form_heading">Fill the form. It is easy.</h1>
                                <div className='success_msg'>{successMessage}</div>
                                <form onSubmit={sendContactForm}>
                                    <div className="row g-3">
                                        <div className="col-sm-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" name="name" id="fname" required placeholder="Full Name" />
                                                <label for="gname">First Name</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" name="email" required id="gmail" placeholder="Email" />
                                                <label for="gmail">Email</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-floating">
                                                <input type="phone" className="form-control" name="phone_number" minLength={10} maxLength={10} required id="cname" placeholder="Number" />
                                                <label for="cname">Phone Number</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" name="message" required placeholder="Leave a message here" id="message" rows="4"></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-dark w-100 py-3" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <br />
                            <br />
                        </div>
                    </div>
                    <div className='map_section'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.6805657910777!2d77.38715211500428!3d28.609358266186124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef5c335a06f1%3A0x18c411ccb30b3988!2sHawklux%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1669531541651!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;