import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import new_launch_product_1 from '../../src/img/new_launch_product_1.jpg';
import product_img_1 from '../../src/img/product_img_1.jpg';


const NewLaunchProduct = () => {
    return (
        <>  
        <div class="container-xxl py-5 mt-4">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                    <h6 className="text-primary text-uppercase mb-2">Our Latest</h6>
                    <h1 className="display-6 mb-4">New Launch Products</h1>
                </div>
            </div>
        </div>
            <OwlCarousel className='owl-theme' items={1} loop margin={1}>
                <div className='item'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5" data-wow-delay="0.1s">
                                <div className="product_img_"><img className="" src={new_launch_product_1} alt="" /></div>
                            </div>
                            <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100 product_items">
                                    <div className="prd_heading_new_launch">HAWKLUX Medical Endoscope Camera System</div>
                                    <div className="row g-2 mb-2 pb-2">
                                        <div className="col-sm-12">
                                            <p className='para_' style={{marginTop: 0}}>The <b>HAWKLUX Medical Endoscope Camera System</b> is composed of a camera control unit, camera head, HDMI cable, DVI cable, power cord, and USB drive for snap and video recording. This system is designed for endoscopic surgery, where the camera captures magnified and detailed images of the surgical area inside the body.</p>
                                            <p className='para_'>The <b>HL-1080-FHD</b> camera is a sophisticated imaging device that is specifically designed to meet the high standards and demands of surgical and medical applications. It goes beyond just delivering high-resolution imaging capabilities by incorporating advanced features such as high dynamic range technology for improved color differentiation and anti-reflection optics for sharper focus. These features are essential for ensuring the best possible visualization during medical procedures.</p>
                                        </div>
                                    </div>
                                    <div className="row g-4">
                                        <div className="col-sm-6">
                                            <Link className="d-inline-flex align-items-center btn btn-outline-primary border-2 p-2" to="/products/endscopy-full-hd-camera">
                                                <span className="px-3">Know More...</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='item'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5" data-wow-delay="0.1s">
                                <div className="product_img_"><img className="" src={product_img_1} alt="" /></div>
                            </div>
                            <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100 product_items">
                                    <div className="prd_heading_new_launch">Cystoscopy T.U.R.P. Set</div>
                                    <div className="row g-2 mb-4 pb-2">
                                        <div className="col-sm-12">
                                            <p>We are the Long Life Surgical dealing in UROLOGY. Work hard to make customer satisfactions about product. We offer you the best possible product at high quality material use Stainless Steel.</p>
                                            <i className="fa fa-check text-primary me-2"></i>Easy to use
                                        </div>
                                        <div className="col-sm-12">
                                            <i className="fa fa-check text-primary me-2"></i>Working Element Single / Double ( Active / Passive )
                                        </div>
                                        <div className="col-sm-12">
                                            <i className="fa fa-check text-primary me-2"></i> Resectoscope Sheath 26 Fr with Ceramic peak
                                        </div>
                                        <div className="col-sm-12">
                                            <i className="fa fa-check text-primary me-2"></i>HD Image Provider Telescope 4 mm 30 Digree.
                                        </div>
                                        <div className="col-sm-12">
                                            <i className="fa fa-check text-primary me-2"></i>IComplete set for procedure at high visualization from Telescope.
                                        </div>
                                    </div>
                                    <div className="row g-4">
                                        <div className="col-sm-6">
                                            <Link className="d-inline-flex align-items-center btn btn-outline-primary border-2 p-2" to="products/cystoscopy-turp-set/1">
                                                <span className="px-3">Know More...</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </>
    )
}

export default NewLaunchProduct;