import HomeSlider from './component/HomeSlider';
import NewLaunchProduct from './component/NewLaunchProduct';
import HomeAboutSection from './component/HomeAboutSection';
import HomeOurProducts from './component/HomeOurProducts';
import HomeOurApplication from './component/HomeOurApplication';
import HomeContactSetion from './component/HomeContactSetion';
import Testimonial from './component/Testimonial';
import Certifications from './component/Certifications';

const Home = () => {
    return (
        <>
            <HomeSlider />
            <NewLaunchProduct />
            <HomeAboutSection />
            <HomeOurProducts />
            <HomeOurApplication />
            <HomeContactSetion />
            <Testimonial />
            <Certifications />
        </>
    )
}

export default Home;