const Faq = () => {
    return (
        <>
            <div className="container-fluid page-header" data-wow-delay="0.1s">
                <div className="container text-center">
                    <h1 className="display-4 text-white animated slideInDown mb-4">FAQ</h1>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <br />
                        <br />
                        <div className='box_layout_shadow'>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What is ICU Bed Electric?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <ul data-mce-fragment="1" >
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Advance Linear Actuator system 04 Pcs Motor, 01 Pc Control Box, 01 Pc Handset.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Four Pieces individual side rials , ABS Moulded.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">ABS Moulded Head and Foot panel with locking and corner buffer.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Frame work made of Rectangular M.S. Tube</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">4 section top made uo of Oval perforated CRCA MS Sheet.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Bed Mounted on 5" Dia Castor with central Braking system.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">S.S. Telescopic IV Rod with 4 Locations</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Overall Approx Size: 2090L x 910W x 460-700H MM.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Finish: Pretreated &amp; Epoxy Powder Coated</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Positions obtained by hand Remote :</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Why is ICU Bed Electric?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <ul data-mce-fragment="1" >
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Advance Linear Actuator system 04 Pcs Motor, 01 Pc Control Box, 01 Pc Handset.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Four Pieces individual side rials , ABS Moulded.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">ABS Moulded Head and Foot panel with locking and corner buffer.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Frame work made of Rectangular M.S. Tube</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">4 section top made uo of Oval perforated CRCA MS Sheet.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Bed Mounted on 5" Dia Castor with central Braking system.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">S.S. Telescopic IV Rod with 4 Locations</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Overall Approx Size: 2090L x 910W x 460-700H MM.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Finish: Pretreated &amp; Epoxy Powder Coated</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Positions obtained by hand Remote :</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            How is ICU Bed Electric?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <ul data-mce-fragment="1" >
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Advance Linear Actuator system 04 Pcs Motor, 01 Pc Control Box, 01 Pc Handset.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Four Pieces individual side rials , ABS Moulded.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">ABS Moulded Head and Foot panel with locking and corner buffer.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Frame work made of Rectangular M.S. Tube</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">4 section top made uo of Oval perforated CRCA MS Sheet.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Bed Mounted on 5" Dia Castor with central Braking system.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">S.S. Telescopic IV Rod with 4 Locations</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Overall Approx Size: 2090L x 910W x 460-700H MM.</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Finish: Pretreated &amp; Epoxy Powder Coated</li>
                                                <li className="9ptdeepGreen" data-mce-fragment="1">Positions obtained by hand Remote :</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>


        </>
    )
}

export default Faq;