import { Link } from "react-router-dom";
import NewLaunchProduct from "../img/new_launch_product_1.jpg";
import NewLaunchProduct_2 from "../img/new_launch_product_1_2.jpg";
import NewLaunchProduct_3 from "../img/new_launch_product_1_3.jpg";
import NewLaunchProduct_4 from "../img/new_launch_product_1_4.jpg";
import NewLaunchProduct_5 from "../img/new_launch_product_1_5.jpg";
import NewLaunchProduct_6 from "../img/new_launch_product_1_6.jpg";
import EndscopyFullHDCameraBanner from "../img/EndscopyFullHDCameraBanner.jpg";

const EndscopyFullHdCamera = () => {
    return (
        <>
            <section id="about_section">
            <img className="w-100" src={EndscopyFullHDCameraBanner} alt="banner" />
                <div className="container-fluid py-5" id="about_wraper_">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6" id="">
                                <div className="h-100">
                                    
                                    {/* <h4 className="">Brand & Model: HAWKLUX  (HL-1080-FHD)</h4> */}
                                    <div className="col-sm-12">
                                        <div className="box_layout_shadow">
                                        <h1 className="section_title_">HAWKLUX Medical Endoscope Camera System</h1>
                                        <div className="product_specfi_titile"><b>Brand & Model:</b> HAWKLUX  (HL-1080-FHD)</div>
                                        <p>The HAWKLUX Medical Endoscope Camera System is composed of a camera control unit, camera head, HDMI cable, DVI cable, power cord, and USB drive for snap and video recording. This system is designed for endoscopic surgery, where the camera captures magnified and detailed images of the surgical area inside the body.</p>
                                        <p>The <b>HL-1080-FHD</b> camera is a sophisticated imaging device that is specifically designed to meet the high standards and demands of surgical and medical applications. It goes beyond just delivering high-resolution imaging capabilities by incorporating advanced features such as high dynamic range technology for improved color differentiation and anti-reflection optics for sharper focus. These features are essential for ensuring the best possible visualization during medical procedures.</p>
                                        <p>Contrary to popular belief, higher megapixels or 4k resolution do not always translate to better image quality. Manufacturers may achieve high megapixel counts at low costs, often at the expense of other important factors. The <b>HL-1080-FHD</b> camera, on the other hand, is built to provide clear and precise imaging with sharp focus and deeper details. Medical professionals can rely on this camera to deliver the highest level of image quality that is essential for making informed decisions during complex surgical or medical procedures.</p>
                                        <p>In the realm of imaging, the size of the sensor is a crucial factor in determining the quality of the final image. The amount of light utilized in creating an image is largely dependent on the size of the sensor. Consequently, a larger sensor can capture more information than a smaller one, resulting in images with higher details, better dynamic range, and reduced noise.</p>
                                        <p>Our camera, the <b>HL-1080-FHD,</b> is equipped with the latest Sony 1/1.8" sensor that offers 50% more surface area than the conventional 1/2.8" sensors used in other cameras. These cheaper sensors compromise on image quality, resulting in images with fewer pixels. The use of our camera's larger sensor results in images with 50% more pixels, providing a significant increase in image quality.</p>
                                        <div className="para_">The <b>HL-1080-FHD</b> camera is highly versatile, featuring multiple modes for different types of surgeries. It has the ability to deliver deep, natural, and realistic images, making it suitable for a wide range of applications including laparoscopy, urology, arthroscopy, laryngoscopy, nasal surgery, and ENT procedures.</div>
                                    <div className="para_">The camera's 4k anti-reflection coupler is designed to be compatible with all rigid endoscopes and some Flexible Laryngoscopes and Urethroscopes. With its advanced features and compatibility, the <b>HL-1080-FHD</b> camera is an ideal choice for medical professionals seeking high-quality imaging technology.</div>
                                    <br />
                                        <Link className="btn btn-primary py-3 px-5" to="/contact">Enquiry Now</Link>
                                        </div>
                                    </div>
                                    
                                        {/* <div className="col-sm-12">
                                            <i className="fa fa-check text-primary me-2"></i>Regular &amp; Easy availability of spare parts for life long &amp; hassle free service solution.
                                        </div> <br /> */}
                                    
                                </div>
                            </div>
                            <div className="col-lg-6" id="right_sec_">
                                <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img className="w-100 img-thumbnail" src={NewLaunchProduct} alt="banner" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="w-100 img-thumbnail" src={NewLaunchProduct_2} alt="banner" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="w-100 img-thumbnail" src={NewLaunchProduct_3} alt="banner" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="w-100 img-thumbnail" src={NewLaunchProduct_4} alt="banner" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="w-100 img-thumbnail" src={NewLaunchProduct_5} alt="banner" />
                                        </div>
                                        <div className="carousel-item">
                                            <img className="w-100 img-thumbnail" src={NewLaunchProduct_6} alt="banner" />
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                                            data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                                            data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>

                                </div>
                                <br />
                                <div className="prd_descript">
                                    <div className="product_specfi_titile">Product Specification:</div>
                                    <p><b>Product Name : </b> Endoscopy Camera</p>
                                    <p><b>PCB Board Material : </b> Double-side copper-clad laminate</p>
                                    <p><b>Resolution : </b> 1920*1080P (Analog 1100 TVL definition, 60FPS frame)</p>
                                    <p><b>Pixels : </b> 2.07M pixels</p>
                                    <p><b>Scanning System : </b> Progressive Scanning</p>
                                    <p><b>Video Output : </b> HDMI/DVI/SDI/CVBS image output port</p>
                                    <p><b>Recording : </b> USB image/video recording supported</p>
                                    <p><b>Video Standard Control : </b> NTSC/PAL</p>
                                    <p><b>Camera Head : </b> Equipped with 4 Programmable buttons with default settings of AWB, Freeze, Recording and Snap.</p>
                                    <p><b>Brightness : </b> Range 0~20, Default: 7</p>
                                    <p><b>AGC : </b> Range 1~10, Default: 10</p>
                                    <p><b>Sharpness : </b> Range 1~10, Default: 7</p>
                                    <p><b>Color Gain : </b> Range 0~20, Default: 10</p>
                                    <p><b>Gama : </b> Range 0.45~0.78, Default: 0.55</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EndscopyFullHdCamera;