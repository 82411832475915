import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <div className="container-fluid bg-dark text-light footer my-0 mb-0 py-6 wow fadeIn" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-white mb-4">Get In Touch</h4>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>C-110, Sector 65, Noida, Uttar Pradesh, India</p>
                            <p className="mb-2"><i className="fa fa-mobile-alt me-3"></i><a href="tel:+919872383819" className="footer_link">+91-9872383819</a></p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i><a href="tel:+91-1203116842" className="footer_link">+91-1203116842</a></p>
                            <p className="mb-2"><i className="fa fa-envelope me-3"></i><a href="mailto:info@hawklux.com" className="footer_link">info@hawklux.com</a></p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-light mb-4">Quick Links</h4>
                            <Link className="btn btn-link" to="/about">About Us</Link>
                            <Link className="btn btn-link" to="/conference">USICON 2023 Conference</Link>
                            <Link className="btn btn-link" to="/privacy-policy">Privacy Policy</Link>
                            <Link className="btn btn-link" to="/terms-conditions">Terms &amp; Conditions</Link>
                            <Link className="btn btn-link" to="/return-policy">Return Policy</Link>
                            <Link className="btn btn-link" to="/faq">FAQ's</Link>
                            <Link className="btn btn-link" to="/contact">Contact</Link>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-light mb-4">Popular Links</h4>
                            <Link className="btn btn-link" to="/products/capital-equipment">Capital Equipment</Link>
                            <Link className="btn btn-link" to="/products/hysteroscopy">Hysteroscopy</Link>
                            <Link to="/products/laparoscopy" className="btn btn-link">Laparoscopy</Link>
                            <Link to="products/urology" className="btn btn-link">Urology</Link>
                            <Link to="/products/gynecology" className="btn btn-link">Gynecology</Link>
                            <Link to="/products/ent" className="btn btn-link">E.N.T Dept.</Link>
                            <Link to="/products/general" className="btn btn-link">General Dept.</Link>
                        </div>
                        <div className="col-lg-3 col-md-6">

                            <h4 className="text-light mb-4">Follow Us</h4>
                            <div className="d-flex pt-2">
                                <a className="btn btn-square btn-outline-light me-1" href="https://twitter.com/hawkluxindia" target="_blank"><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-outline-light me-1" href="https://www.facebook.com/hawklux" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-outline-light me-1" href="https://www.youtube.com/@hawklux" target="_blank"><i className="fab fa-youtube"></i></a>
                                <a className="btn btn-square btn-outline-light me-0" href="https://www.linkedin.com/company/hawklux" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid copyright text-light py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            Copyright &copy; 2021 Hawklux. All Rights Reserved
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;