import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import Certifications from './Certifications';
import IMG_001 from '../img/conference/IMG_001.jpeg';
import IMG_002 from '../img/conference/IMG_002.jpeg';
import IMG_003 from '../img/conference/IMG_003.jpeg';
import IMG_004 from '../img/conference/IMG_004.jpeg';
import IMG_005 from '../img/conference/IMG_005.jpeg';
import IMG_006 from '../img/conference/IMG_006.jpeg';
import IMG_007 from '../img/conference/IMG_007.jpeg';
import IMG_008 from '../img/conference/IMG_008.jpeg';
import IMG_009 from '../img/conference/IMG_009.jpeg';
import IMG_010 from '../img/conference/IMG_010.jpg';
import USICON_2023_Conference_Video from '../img/conference/USICON_2023_Conference_Video.mp4';
import { CImage } from '@coreui/react';


const Conference = () => {
    return (
        <>
            <div className="container-fluid page-header" data-wow-delay="0.1s">
                <div className="container text-center">
                    <h1 className="display-4 text-white animated slideInDown mb-4">USICON 2023 Conference</h1>
                </div>
            </div>
            <section id="about_section">
                <div className="container-fluid py-6">
                    <div className="container">
                        <br />
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='box_layout_shadow'>
                                    <div className="section_title_">Hawklux @USICON 2023</div>
                                    <p>With quality and technology, our prime focus is on after-sale service and support to our esteemed customers which forms the integral part of our company. We have dedicated team of service engineers in different zones of the country. A time of max 48 hours for the 1st attempt to resolve the issue. With our in-house manufacturing unit, we are able to provide after-sale service with an ease as we are not depending on others. We make sure we take customer's feedback seriously and provide full support to solve their queries in quickest time possible.</p>
                                    <div className="section_title_">Images</div>
                                    <div className='row'>
                                    <div className='col-sm-6'><CImage fluid={true} thumbnail={true} src={IMG_001} /></div>
                                    <div className='col-sm-6'><CImage fluid={true} thumbnail={true} src={IMG_002} /></div>
                                    <div className='col-sm-6 mr_top_25'><CImage fluid={true} thumbnail={true} src={IMG_003} /></div>
                                    <div className='col-sm-6 mr_top_25'><CImage fluid={true} thumbnail={true} src={IMG_004} /></div>
                                    <div className='col-sm-6 mr_top_25'><CImage fluid={true} thumbnail={true} src={IMG_005} /></div>
                                    <div className='col-sm-6 mr_top_25'><CImage fluid={true} thumbnail={true} src={IMG_010} /></div>
                                    </div>
                                    <div className='row'>
                                    <div className='col-sm-3 mr_top_25'><CImage fluid={true} thumbnail={true} src={IMG_006} /></div>
                                    <div className='col-sm-3 mr_top_25'><CImage fluid={true} thumbnail={true} src={IMG_007} /></div>
                                    <div className='col-sm-3 mr_top_25'><CImage fluid={true} thumbnail={true} src={IMG_008} /></div>
                                    <div className='col-sm-3 mr_top_25'><CImage fluid={true} thumbnail={true} src={IMG_009} /></div>
                                    </div>
                                    <div className="section_title_ mr_top_25">Training sessions using Hawklux Camera - HL-1080-FHD</div>
                                    <div className='row'>
                                    <div className='col-sm-4 mr_top_25'>
                                        <div className='video_col'>
                                        <Player>
                                            <source src={USICON_2023_Conference_Video} />
                                        </Player>
                                        </div>
                                    </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Certifications />
        </>
    )
}

export default Conference;