import { Link } from "react-router-dom";
import about_image_1 from '../img/about-1.jpg';
import about_image_2 from '../img/about-2.jpg';

const HomeAboutSection = () => {
    return (
        <>
            <section id="about_section">
                <div className="container-fluid py-6">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <h6 className="text-primary text-uppercase mb-2">About Us</h6>
                                    <h1 className="display-6 mb-4"> At Surgical Holdings, we strive for the best possible quality in everything we do</h1>
                                    <p>However, it isn’t quite enough to say that we manufacture and repair surgical instruments and rigid endoscopes. At Surgical Holdings, we strive for the best possible quality in everything we do. This has been the foundation of our approach ever since George Poole established his own soon-to-be-successful business in 1910, making finely-crafted surgical instruments.</p>
                                    <div className="row g-2 mb-4 pb-2">
                                        <div className="col-sm-6">
                                            <i className="fa fa-check text-primary me-2"></i>Capatial Equipments
                                        </div>
                                        <div className="col-sm-6">
                                            <i className="fa fa-check text-primary me-2"></i>OT / ICU Equipments
                                        </div>
                                        <div className="col-sm-6">
                                            <i className="fa fa-check text-primary me-2"></i>Hospital Furniture
                                        </div>
                                        <div className="col-sm-6">
                                            <i className="fa fa-check text-primary me-2"></i>Endoscopy Camera
                                        </div>
                                    </div>
                                    <div className="row g-4">
                                        <div className="col-sm-6">
                                            <Link className="btn btn-primary py-3 px-5" to="/about">Read More</Link>
                                        </div>
                                        <div className="col-sm-6">
                                            <a className="d-inline-flex align-items-center btn btn-outline-primary border-2 p-2" href="tel:+91-9872383819">
                                                <span className="flex-shrink-0 btn-square bg-primary">
                                                    <i className="fa fa-phone-alt text-white"></i>
                                                </span>
                                                <span className="px-3">+91-9872383819</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '600px' }}>
                                    <img className="position-absolute " src={about_image_1} alt="" />
                                    <img className="position-absolute top-0 start-0 bg-white pe-3 pb-3" src={about_image_2} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAboutSection;