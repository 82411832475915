import Certifications from './Certifications';
import hawklux_about_image from '../img/hawklux_about_image.jpg';


const About = () => {
    return (
        <>
            <div className="container-fluid page-header" data-wow-delay="0.1s">
                <div className="container text-center">
                    <h1 className="display-4 text-white animated slideInDown mb-4">About Us</h1>
                </div>
            </div>
            <section id="about_section">
                <div className="container-fluid py-6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="h-100">
                                    <div className="section_title_">About Hawklux</div>
                                    <p>Hawklux India Private Limited is one of the emerging manufacturer of Endoscopy &amp; Laparoscopy Cameras and Other Medical Devices in India with best-in-class German technology in collaboration with German OEM Camera manufacturing company.
                                        After having a rich experience of around 10 years in field of Sales and Distribution of medical equipment’s, Company expands itself into manufacturing and Trademark its Brand name “HAWKLUX”
                                    </p>
                                    <p>The Corona Pandemic make us realise the need of the revolution in the medical field in our country. In early 2022 started our new journey to facilitate our Doctors and Hospitals with medical equipment’s at very competitive and reasonable price.
                                        Our Vision is to make all the hospitals of our country equipped with best medical devices and other surgical instruments, so every common man can get best in class medical services at their respective local area hospitals.
                                    </p>
                                    <p>Right from its inception, the management laid emphasis on high quality medical devices. Thereafter the Quality management system was conceived & implemented. Which is very rare to find in India. With trained & experienced staff, we strive for the best quality product with latest technology and features which other companies fails to provide at cost effective solution.</p>
                                    <p>To make our products cost effective we are just importing the importing the best in class lenses & sensor chips and making our products ready locally under Make in India program. </p>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-5">
                                <div className="position-relative" style={{ minHeight: '550px' }}>
                                    <img className="position-absolute img-thumbnail" src={hawklux_about_image} alt="" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='box_layout_shadow'>
                                    <div className="section_title_">After Sale Support</div>
                                    <p>With quality and technology, our prime focus is on after-sale service and support to our esteemed customers which forms the integral part of our company. We have dedicated team of service engineers in different zones of the country. A time of max 48 hours for the 1st attempt to resolve the issue. With our in-house manufacturing unit, we are able to provide after-sale service with an ease as we are not depending on others. We make sure we take customer's feedback seriously and provide full support to solve their queries in quickest time possible.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Certifications />
        </>
    )
}

export default About;