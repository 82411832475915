const ReturnPolicy = () => {
    return (
        <>
            <div className="container-fluid page-header" data-wow-delay="0.1s">
                <div className="container text-center">
                    <h1 className="display-4 text-white animated slideInDown mb-4">Return Policy</h1>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <br />
                        <br />
                        <div className='box_layout_shadow'>
                            <div className="point_heading_main"><span>1.</span>Policy Around Returns &amp; Exchanges</div>
                            <p>we grant you a period of 7 Days from the Delivery date to return the products (except those mentioned in Clause 1.1 below, for which the right to cancel is excluded). In case you return the goods within the contractual term of the right of withdrawal, you will only be reimbursed with the amount paid for said products. Delivery charges will not be reimbursed. Unless you hand the goods over in a HAWKLUX store in India, you shall bear the direct cost of returning the goods and import taxes and duties.</p>
                            <p>We reserve the right not to accept return of products which: </p>
                            <p>(a) we believe are being returned after use, or (b) damaged (except where the return is on account of damaged goods having been delivered to you).</p>
                            <p>The withdrawal period will expire after 7 Days from the day on which you received the Order Confirmation.</p>
                            <p>To exercise the right of withdrawal, you may notify us at HAWKLUX by sending an email to info@hawklux.com  or by writing to our contact form, of your decision to withdraw from this contract by an unequivocal statement (example: a letter sent by post or email or email).</p>
                            <p>To meet the withdrawal deadline, it is sufficient for you to send your communication concerning your exercise of the right of withdrawal before the withdrawal period has expired.</p>
                            <p>If you decide to withdraw from this Contract, and subject to above, we will return to you all payments received from you, excluding delivery charges without any undue delay, within 15 days of the date on which the returned product is received to our address. The refund will be issued to the original payment method used during the purchase.</p>
                            <p>You shall send back or deliver the goods or hand them over to us at HAWKLUX’s registered office in India. Or via a courier that we will send to your home without undue delay and in any event not later than one month from the Order Confirmation date. Unless you hand the goods over to authorised person in HAWKLUX. You shall bear the direct cost of returning the goods. You are only liable for any diminished value of the goods resulting from handling other than what is necessary to establish the nature, characteristics and functioning of the goods.</p>
                            <div className="point_heading_main"><span>2.</span>Common Provisions</div>
                            <p>you shall not have the right to withdraw from the Contract when it is for the delivery of any of the following Products: </p>
                            <ol type="I">
                                <li>Customised items</li>
                                <li>Endoscopes</li>
                                <li>Sealed goods which are not suitable for return due to hygiene reasons and where unsealed after delivery</li>
                                <li>Damaged goods</li>
                                <li>Goods which have been used prior to withdrawal from contract</li>
                                <li>Accessories</li>
                            </ol>
                            <p>Your right to cancel the Contract shall apply exclusively to the products that are returned in the same condition in which you received them. No reimbursement will be made if the product has been used once it has been opened, for products that are not in the same condition as when they were delivered or if they have been damaged, so take care of the products(s) while in your possession.</p>
                            <p>Please return the products using or including all their original packaging, instructions and other documents, if any, accompanying the products. In any case, you must send the product to be returned together with the receipt that you received when the product was delivered. You will find a summary on exercising this cancellation right when you receive the order.</p>
                            <p>Upon cancellation, the respective products shall be returned as follows: </p>
                            <p>a) Returns by Courier: You may return the product to the address given by us at the time of placing return request. The invoice provided along with return shipment should contain the value mentioned by us while placing a return request. We ask you to return the product within 7 Days from the date of Delivery, together with the receipt. After examining the article, we will inform you of whether you have the right to reimbursement of the amounts paid. The refund (excluding delivery charges) will be paid as soon as possible and, in all cases, within 15 days from the date on which We receive the returned product to our given address. Notwithstanding the foregoing, we may withhold reimbursement until we have received the goods back or you have supplied evidence of having sent back the goods, whichever is the earliest. </p>
                            <p>The refund will always be paid using the same payment means you used to pay for your purchase. If you have any questions, you can contact us on our contact form.</p>
                            <div className="point_heading_main"><span>3.</span>Returns of Defective Products</div>
                            <p>If you think that at the moment of delivery the product is not as stipulated in the Contract, you must contact us immediately on our contact form, providing the product details and the damage sustained; you can also call us on +919872383819. You must return the product to the address given by us while placing return request. The refunding or replacement of the article shall take place as soon as possible and in all cases within 15 days from the date on which we send you an email confirming that the refund or replacement of the product is going ahead. If a defect or damage is confirmed on the returned products, we will give you a complete refund including the charges you have accrued of delivery and return. The refund will always be paid using the same payment means you used to pay for your purchase. All rights recognised in current legislation shall be, in any case, safeguarded. Your collection is already set up for you with fields and content. Add your own, or import content from a CSV file. Add fields for any type of content you want to display, such as rich text, images, videos and more. You can also collect and store information from your site visitors using input elements like custom forms and fields.</p>
                            <p>Be sure to click Sync after making changes in a collection, so visitors can see your newest content on your live site. Preview your site to check that all your elements are displaying content from the right collection fields.</p>
                            
                        </div>
                        <br />
                        <br />

                    </div>
                </div>
            </div>
        </>
    )
}

export default ReturnPolicy;