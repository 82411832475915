import banner_1 from '../../src/img/banner_1.png';
import banner_2 from '../../src/img/banner_2.png';
import banner_3 from '../../src/img/banner_3.png';

const HomeSlider = () => {
    return (
        <>
            <div className="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s">
                <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src={banner_1} alt="banner" />
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src={banner_2} alt="banner" />
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src={banner_3} alt="banner" />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default HomeSlider;