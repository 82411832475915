import { useState } from 'react';
import emailjs from '@emailjs/browser';
import contact_sec_man_img from '../../src/img/contact_sec_man_img.png';


const HomeContactSetion = () => {
    const [successMessage, setSuccessMessage] = useState();
    const sendContactForm = (e) => {
        e.preventDefault();
        console.log("Click");
        emailjs.sendForm('service_usc4zzd', 'template_og9tsto', e.target, 'oJbBqRMUkROA7zc-o').then((res) => {
             console.log(res.text);
             setSuccessMessage("Your query has been submited successfully!!!");
             setTimeout(() => {
                setSuccessMessage(null);
             }, 4000);
             
        }).catch((error) => {
            console.log(error.text);
        });
    }
    return (
        <>
            <div className="container-fluid py-6" id="contact_sec_">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="contact_sec_img">
                                <img src={contact_sec_man_img} width="" height="" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 my-6 mb-0 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="bg-primary text-center p-5" id="contact_form_sec">
                                <h1 className="mb-4 form_heading">Fill the form. It is easy.</h1>
                                <form onSubmit={sendContactForm}>
                                    <div className="row g-3">
                                        <div className="col-sm-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" name="name" id="fname" required placeholder="Full Name" />
                                                <label for="gname">First Name</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" name="email" required id="gmail" placeholder="Email" />
                                                <label for="gmail">Email</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-floating">
                                                <input type="phone" className="form-control" name="phone_number" minLength={10} maxLength={10} required id="cname" placeholder="Number" />
                                                <label for="cname">Phone Number</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" name="message" required placeholder="Leave a message here" id="message" rows="4"></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-dark w-100 py-3" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeContactSetion;