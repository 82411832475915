import { Link } from "react-router-dom";
import ProductItems from '../../ProductItems.json';

const Laparoscopy = () => {
    return (
        <>
            <div className="container-fluid page-header">
                <div className="container text-center">
                    <h1 className="display-4 text-white animated slideInDown mb-4">Laparoscopy</h1>
                </div>
            </div>
            <div className="courses my-3 py-5 pb-4">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                        <h1 className="display-6 mb-4">Laparoscopy</h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        {
                            ProductItems.map((item, i) => {
                                if (item.category_type === "Laparoscopy") {
                                return <>
                                    <div className="col-lg-4 col-md-6" key={i}>
                                        <div className="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                            <div className="text-center p-4 pt-0">
                                                <h5 className="mb-3 product_title">{item.title}</h5>
                                                <p className="prd_desc_on_list">{item.description}</p>
                                            </div>
                                            <div className="position-relative mt-auto">
                                                <img className="img-fluid" src={item.image[0]} alt="" />
                                                <div className="courses-overlay">
                                                    <Link className="btn btn-outline-primary border-2" to={`/products/${item.url}/${item.id}`}>Read More</Link>
                                                </div>
                                            </div>
                                            <div className="enquiry_not_btn_sec">
                                                <Link className="enquiry_not_btn" to="/contact">Enquiry Now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </> }
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Laparoscopy;