
import certification_1_min from '../img/certification_1_min.jpg';
import certification_2_min from '../img/certification_2_min.jpg';
import certification_3_min from '../img/certification_3_min.jpg';

const Certifications = () => {
    return (
        <>
            <div className='container' id='certification_sec'>
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                <h1 className="display-6 mb-4">Our Certifications</h1>
            </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="certification_image_sec">
                        <a href={certification_2_min} target="_blank"><img src={certification_1_min} width="" height="" alt="" /></a>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="certification_image_sec">
                            <a href={certification_2_min} target="_blank"><img src={certification_2_min} width="" height="" alt="" /></a>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="certification_image_sec">
                        <a href={certification_2_min} target="_blank"><img src={certification_3_min} width="" height="" alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Certifications;