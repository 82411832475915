import { Link } from "react-router-dom";
import product_img_1 from '../../src/img/product_img_1.jpg';
import product_img_2 from '../../src/img/product_img_2.jpg';
import product_img_3 from '../../src/img/product_img_3.jpg';
import product_img_4 from '../../src/img/product_img_4.jpg';
import product_img_5 from '../../src/img/product_img_5.jpg';
import product_img_6 from '../../src/img/product_img_6.jpg';

const HomeOurProducts = () => {
    return (
        <>
            <div className="courses my-3 py-5 pb-4">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="text-primary text-uppercase mb-2">Tranding Products</h6>
                        <h1 className="display-6 mb-4">Our Products</h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                <div className="text-center p-4 pt-0">
                                    {/* <div className="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">₹ 8,000</div> */}
                                    <h5 className="mb-3">Cystoscopy T.U.R.P. Set</h5>
                                    <p className="product_des_in_grid">We are the Long Life Surgical dealing in UROLOGY. Work hard to make customer satisfactions about product. We offer you the best possible product at high quality. material use Stainless Steel.</p>
                                </div>
                                <div className="position-relative mt-auto">
                                    <img className="img-fluid" src={product_img_1} alt="" />
                                    <div className="courses-overlay">
                                        <Link className="btn btn-outline-primary border-2" to="/products/cystoscopy-turp-set/1">Read More</Link>
                                    </div>
                                </div>
                                <div className="enquiry_not_btn_sec">
                                    <Link className="enquiry_not_btn" to="/contact">Enquiry Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                <div className="text-center p-4 pt-0">
                                    {/* <div className="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">₹ 3900</div> */}
                                    <h5 className="mb-3">Digital Video Colposcope 222 DX</h5>
                                    <p className="product_des_in_grid">In order to keep pace with the never-ending demands of customers, we are involved in offering a wide range of Digital Video Colposcope.</p>
                                </div>
                                <div className="position-relative mt-auto">
                                    <img className="img-fluid" src={product_img_2} alt="" />
                                    <div className="courses-overlay">
                                        <Link className="btn btn-outline-primary border-2" to="/products/digital-video-colposcope-222-dx/2">Read More</Link>
                                    </div>
                                </div>
                                <div className="enquiry_not_btn_sec">
                                    <Link className="enquiry_not_btn" to="/contact">Enquiry Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                <div className="text-center p-4 pt-0">
                                    {/* <div className="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">₹ 11,000</div> */}
                                    <h5 className="mb-3">LED Light Source</h5>
                                    <p className="product_des_in_grid">We present an extensive range of Light Source for all your surgical procedures and measurement applications. In our equipment, we use LED and halogen source of light for an excellent range of illumination. One of the best options that can replace xenon source of light, these light equipment have energy efficient and long working life with zero maintenance.</p>
                                </div>
                                <div className="position-relative mt-auto">
                                    <img className="img-fluid" src={product_img_3} alt="" />
                                    <div className="courses-overlay">
                                        <Link className="btn btn-outline-primary border-2" to="/products/led-light-source/3">Read More</Link>
                                    </div>
                                </div>
                                <div className="enquiry_not_btn_sec">
                                    <Link className="enquiry_not_btn" to="/contact">Enquiry Now</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                <div className="text-center p-4 pt-0">
                                    {/* <div className="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">₹ 28,000</div> */}
                                    <h5 className="mb-3">Endoscopy Camera</h5>
                                    <p className="product_des_in_grid">Our latest technological innovation SLIDe*, eliminates the compatibility issue between the different light cables standards. You will be able to operate with all your instruments without concerns.
iCare takes care of everything! The standard switch is done inside the control unit for more simplicity and better design, but above all to always provide the same light performance.</p>
                                </div>
                                <div className="position-relative mt-auto">
                                    <img className="img-fluid" src={product_img_4} alt="" />
                                    <div className="courses-overlay">
                                        <Link className="btn btn-outline-primary border-2" to="/products/endoscopy-camera/4">Read More</Link>
                                    </div>
                                </div>
                                <div className="enquiry_not_btn_sec">
                                    <Link className="enquiry_not_btn" to="/contact">Enquiry Now</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                <div className="text-center p-4 pt-0">
                                    {/* <div className="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">₹ 4500</div> */}
                                    <h5 className="mb-3">Attendant Bed Cum Chair</h5>
                                    <p className="product_des_in_grid">We are offering Attendant Bed  to our clients.</p>
                                </div>
                                <div className="position-relative mt-auto">
                                    <img className="img-fluid" src={product_img_5} alt="" />
                                    <div className="courses-overlay">
                                        <Link className="btn btn-outline-primary border-2" to="/products/attendant-bed-cum-chair/5">Read More</Link>
                                    </div>
                                </div>
                                <div className="enquiry_not_btn_sec">
                                    <Link className="enquiry_not_btn" to="/contact">Enquiry Now</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="courses-item d-flex flex-column bg-white overflow-hidden h-100">
                                <div className="text-center p-4 pt-0">
                                    {/* <div className="d-inline-block bg-primary text-white fs-5 py-1 px-4 mb-4">₹ 7800</div> */}
                                    <h5 className="mb-3">ICU Bed Mechanically</h5>
                                    <p className="product_des_in_grid">To meet the various requirements of the customers, we are involved in offering a wide assortment of Mechanical-Hospital-Beds.</p>
                                </div>
                                <div className="position-relative mt-auto">
                                    <img className="img-fluid" src={product_img_6} alt="" />
                                    <div className="courses-overlay">
                                        <Link className="btn btn-outline-primary border-2" to="/products/icu-bed-mechanically/6">Read More</Link>
                                    </div>
                                </div>
                                <div className="enquiry_not_btn_sec">
                                    <Link className="enquiry_not_btn" to="/contact">Enquiry Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeOurProducts;