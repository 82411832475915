import { useState } from 'react';
import { Link } from 'react-router-dom';
import useWindowDimensions from './useWindowDimensions';
import logo from '../../src/img/logo.jpg';

const Header = () => {
    const { width } = useWindowDimensions();
    const [mobMenu, setMobMenu] = useState(false);
    const [showMobMenu, setShowMobMenu] = useState(false);
    const hideMenubar = (e) => {
        if (width < 1024 ) {
            setMobMenu(true);
            setShowMobMenu(false);
        }
    }
    const showMenubar = () => {
        if (width < 1024 ) {
            if (showMobMenu === true) {
                setShowMobMenu(false);
            } else {
                setShowMobMenu(true);
            }
            setMobMenu(false);
        }
    }
    
    
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
                <Link to="/" className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5">
                    <h2 className="m-0" id='header_logo'>
                        <img src={logo} width="200" alt="" />
                    </h2>
                </Link>
                <button type="button" className="navbar-toggler me-4" onClick={showMenubar}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={showMobMenu ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'} id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0" id={mobMenu ? 'navigation_menu' : ''}>
                        <Link to="/" className="nav-item nav-link active">Home</Link>
                        {/* <Link to="/about" className="nav-item nav-link" onClick={hideMenubar}>About</Link> */}
                        <Link to="/products" className="nav-item nav-link" onClick={hideMenubar}>Products</Link>
                        <div className="nav-item">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Capital Equipments</Link>
                            <div className="dropdown-menu bg-light m-0">
                                <Link to="/products/endoscopy-camera" className="dropdown-item" onClick={hideMenubar}>Endoscopy Camera</Link>
                                <Link to="/products/led-light-source" className="dropdown-item" onClick={hideMenubar}>LED Light Source</Link>
                                <Link to="/products/co2-insufflator" className="dropdown-item" onClick={hideMenubar}>CO2 Insufflator</Link>
                                {/* <Link to="/products/colposcope" className="dropdown-item" onClick={hideMenubar}>Colposcope</Link> */}
                            </div>
                        </div>
                        <div className="nav-item">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">OT/ICU Equipments</Link>
                            <div className="dropdown-menu bg-light m-0">
                                <Link to="/products/ot-tables" className="dropdown-item" onClick={hideMenubar}>OT Tables</Link>
                                <Link to="/products/ot-lights" className="dropdown-item" onClick={hideMenubar}>OT Lights</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Hospital Furniture</Link>
                            <div className="dropdown-menu bg-light m-0">
                                <Link to="/products/hospital-beds" className="dropdown-item" onClick={hideMenubar}>Hospital Beds</Link>
                                <Link to="/products/other-furnitures" className="dropdown-item" onClick={hideMenubar}>Other Furniture</Link>
                            </div>
                        </div>
                        <div className="nav-item">
                            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Department</Link>
                            <div className="dropdown-menu bg-light m-0">
                                <Link to="/products/laparoscopy" className="dropdown-item" onClick={hideMenubar}>Laparoscopy</Link>
                                <Link to="products/urology" className="dropdown-item" onClick={hideMenubar}>Urology</Link>
                                <Link to="/products/gynecology" className="dropdown-item" onClick={hideMenubar}>Gynecology</Link>
                                <Link to="/products/ent" className="dropdown-item" onClick={hideMenubar}>E.N.T</Link>
                                <Link to="/products/general" className="dropdown-item" onClick={hideMenubar}>General</Link>
                            </div>
                        </div>
                        
                        <Link to="/contact" className="nav-item nav-link" onClick={hideMenubar}>Contact</Link>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header;