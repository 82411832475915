import client_1 from '../../src/img/client_1.jpg';
import client_2 from '../../src/img/client_2.jpg';
import client_3 from '../../src/img/client_3.jpg';
import client_4 from '../../src/img/client_4.jpg';

const Testimonial = () => {
    return (
        <>
        <div className="py-6">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                <h1 className="display-6 mb-4">Great Experience Of Our Clients</h1>
            </div>
            <div className="row g-0 team-items">
                {/* <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="team-item">
                        <div className="client_image_sec">
                            <img className="client_img" src={client_4} alt="" />
                            <div className="team-social text-center">
                                <a className="btn btn-square btn-outline-primary border-2 m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-outline-primary border-2 m-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-outline-primary border-2 m-1" href=""><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                        <div className="bg-light text-center p-4">
                            <h5 className="mt-2">Dr Akash Garg Clinic</h5>
                            <span>"As Director of Materials Management for a major teaching facility and part of a four-hospital system, I've had and continue to have a wonderful, supportive, and overall positive experience with Hawklux."</span>
                        </div>
                    </div>
                </div> */}
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="team-item">
                        <div className="client_image_sec">
                            <img className="client_img" src={client_1} alt="" />
                        </div>
                        <div className="bg-light text-center p-4">
                            <h5 className="mt-2">Dr. Milan Sachan</h5>
                            <span>"I have bought HL-1080-FHD Endoscopy Camera from Hawklux, I would recommend this company for medical supplies and I'm sure I'll be making more purchases in the future because this was a great experience.""</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item">
                        <div className="client_image_sec">
                            <img className="client_img" src={client_2} alt="" />
                        </div>
                        <div className="bg-light text-center p-4">
                            <h5 className="mt-2">Dr. Sanjay Jain</h5>
                            <span>"My association with Gyan has been for a no of years. The products I have used till date are of superior quality and long term durability. My complete satisfaction with the products and Services offered by them propells me to further recommend him to other doctors / hospitals."</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="team-item">
                        <div className="client_image_sec">
                            <img className="client_img" src={client_3} alt="" />
                        </div>
                        <div className="bg-light text-center p-4">
                            <h5 className="mt-2">Dr. Vikas Giri</h5>
                            <span>"I had placed an order with Hawklux India Pvt Ltd for TURP Set and FHD Endoscopy Camera. After using for months the quality of the products is still very good. The kind of the service company provides was tremendous. I have already recommend products of this company to my fellow surgeons and will do to more."</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}

export default Testimonial;