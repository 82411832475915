const TermsConditions = () => {
    return (
        <>
            <div className="container-fluid page-header" data-wow-delay="0.1s">
                <div className="container text-center">
                    <h1 className="display-4 text-white animated slideInDown mb-4">Terms &amp; Conditions</h1>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <br />
                        <br />
                        <div className='box_layout_shadow'>
                            <div className="point_heading_main"><span>1.</span> CONDITIONS OF USE</div>
                            <p>Welcome to our online Store, HAWKLUX and its associates provide their services to you subject to the following conditions. If you visit or shop within this website, you accept these conditions. Please read them carefully.</p>
                            <div className="point_heading_main"><span>2.</span>PRIVACY</div>
                            <p>Please review our Privacy Notice, which also governs your visit to our website, to understand our practices.</p>
                            <div className="point_heading_main"><span>3.</span>ELECTRONIC COMMUNICATIONS</div>
                            <p>When you visit HAWKLUX or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on this site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
                            <div className="point_heading_main"><span>4.</span>COPYRIGHT</div>
                            <p>All content included on this site, such as text, graphics, logos, button icons, images, and audio clips, digital downloads, data compilations, and software, is the property of HAWKLUX or its content suppliers and protected by international copyright laws. The compilation of all content on this site is the exclusive property of Hawklux India Private Limited, with copyright authorship for this collection by HAWKLUX, and protected by international copyright laws.</p>
                            <div className="point_heading_main"><span>5.</span>TRADE MARKS</div>
                            <p>HAWKLUX’s trademarks and trade dress may not be used in connection with any product or service that is not HAWKLUX’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits HAWKLUX. All other trademarks not owned by HAWKLUX or its subsidiaries that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by HAWKLUX or its subsidiaries.</p>
                            <div className="point_heading_main"><span>6.</span>LICENSE AND SITE ACCESS</div>
                            <p>HAWKLUX grants you a limited license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of HAWKLUX. This license does not include any resale or commercial use of this site or its contents: any collection and use of any product listings, descriptions, or prices: any derivative use of this site or its contents: any downloading or copying of account information for the benefit of another merchant: or any use of data mining, robots, or similar data gathering and extraction tools.</p>
                            <p>This site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of HAWKLUX.</p>
                            <p>You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of HAWKLUX and our associates without express written consent. You may not use any Meta tags or any other "hidden text" utilizing HAWKLUX’s name or trademarks without the express written consent of HAWKLUX. Any unauthorized use terminates the permission or license granted by HAWKLUX.</p>
                            <p>You are granted a limited, revocable, and nonexclusive right to create a hyperlink to the home page of HAWKLUX so long as the link does not portray HAWKLUX, its associates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any HAWKLUX logo or other proprietary graphic or trademark as part of the link without express written permission.</p>
                            <div className="point_heading_main"><span>7.</span>YOUR MEMBERSHIP ACCOUNT</div>
                            <p>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. If you are under 18, you may use our website only with involvement of a parent or guardian. HAWKLUX and its associates reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in their sole discretion.</p>
                            <div className="point_heading_main"><span>8.</span>REVIEWS, COMMENTS, EMAILS, AND OTHER CONTENT</div>
                            <p>Visitors may post reviews, comments, and other content: and submit suggestions, ideas, comments, questions, or other information, so long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam." You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of a card or other content. HAWKLUX reserves the right (but not the obligation) to remove or edit such content, but does not regularly review posted content. If you do post content or submit material, and unless we indicate otherwise, you grant HAWKLUX and its associates a nonexclusive, royalty free, perpetual, irrevocable, and fully sub licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media. You grant HAWKLUX and its associates and sublicenses the right to use the name that you submit in connection with such content, if they choose. You represent and warrant that you own or otherwise control all of the rights to the content that you post: that the content is accurate: that use of the content you supply does not violate this policy and will not cause injury to any person or entity and that you will indemnify HAWKLUX or its associates for all claims resulting from content you supply. HAWKLUX has the right but not the obligation to monitor and edit or remove any activity or content. HAWKLUX takes no responsibility and assumes no liability for any content posted by you or any third party.</p>
                            
                            <div className="point_heading_main"><span>9.</span>PRODUCT DESCRIPTIONS</div>
                            <p>HAWKLUX and its associates attempt to be as accurate as possible. However, HAWKLUX does not warrant that product descriptions or other content of this site is accurate, complete, reliable, current, or error-free. If a product offered by HAWKLUX itself is not as described, your sole remedy is to return it in unused condition.</p>
                            <p>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY HAWKLUX MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, HAWKLUX DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. HAWKLUX DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM HAWKLUX ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
                            <p>HAWKLUX warrants the products against defects in material or workmanship as follows:</p>
                            <ol type="1">
                                <li>LABOR : For a period of one (1) year from the date of purchase, if this Product is determined to be defective, HAWKLUX will repair or replace the Product, at its option, at no charge, After the Warranty Period, you must pay for all labour charges.</li>
                                <li>PARTS: In addition, HAWKLUX will supply, at no charge, new or rebuilt replacements in exchange for defective parts for a period of one (1) year.</li>
                            </ol>
                            <p>To obtain warranty service, you must take the Product, or deliver the Product freight prepaid, in either its original packaging or packaging affording an equal degree of protection, to our head office in Noida.</p>
                            <p>To obtain warranty service, you must take the Product, or deliver the Product freight prepaid, in either its original packaging or packaging affording an equal degree of protection, to our head office in Noida.</p>
                            <p>This warranty does not cover customer instruction, installation, set up adjustments or signal reception problems. This warranty does not cover cosmetic damage or damage due to acts of God, accident, misuse, abuse, negligence, commercial use, or modification of, or to any part of the Product.</p>
                            <p>This warranty does not cover damage due to improper operation or maintenance, connection to improper voltage supply, or attempted repair by anyone other company Proof of purchase in the form of a bill of sale or receipted invoice which is evidence that the unit is within the Warranty period must be presented to obtain warranty service.</p>
                            <p>Foregoing limited warranty does not include reimbursement to customer for its costs and expenses of transportation, freight, labor, removal, or other expenses that might be incurred in connection with customer’s returning the defective items to HAWKLUX or in connection with HAWKLUX sending to customer any repaired or replaced items.</p>
                            <div className="point_heading_main"><span>10.</span>APPLICABLE LAW</div>
                            <p>By visiting www.hawklux.com, you agree that the laws of the state of Uttar Pradesh, India, without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute of any sort that might arise between you and HAWKLUX or its associates.</p>
                            <div className="point_heading_main"><span>11.</span>DISPUTES</div>
                            <p>Any dispute relating in any way to your visit to HAWKLUX or to products you purchase through HAWKLUX shall be submitted to confidential arbitration in Noida, India, except that, to the extent you have in any manner violated or threatened to violate HAWKLUX intellectual property rights, HAWKLUX may seek injunctive or other appropriate relief in any state or federal court in the state of Delhi, India, and you consent to exclusive jurisdiction and venue in such courts.</p>
                            <p>Arbitration under this agreement shall be conducted under the rules then prevailing of the American Arbitration Association. The arbitrator’s award shall be binding and may be entered as a judgment in any court of competent jurisdiction. To the fullest extent permitted by applicable law, no arbitration under this Agreement shall be joined to an arbitration involving any other party subject to this Agreement, whether through class arbitration proceedings or otherwise.</p>
                            <div className="point_heading_main"><span>12.</span>SITE POLICIES, MODIFICATION, AND SEVERABILITY</div>
                            <p>please review our other policies, such as our Shipping and Returns policy, posted on this site. These policies also govern your visit to HAWKLUX. We reserve the right to make changes to our site, policies, and these Conditions of Use at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.</p>
                            <div className="point_heading_main"><span>13.</span>QUESTIONS: </div>
                            <p>Questions regarding our Conditions of Usage, Privacy Policy, or other policy related material can be directed to our support staff by clicking on the "Contact Us" link in the footer menu. Or you can email us at info@hawklux.com</p>
                            <div className="point_heading_main"><span>14.</span>RISK OF LOSS</div>
                            <p>All items purchased from HAWKLUX are made pursuant to a shipment contract. This basically means that the risk of loss and title for such items pass to you upon our delivery to the carrier.</p>
                        </div>
                        <br />
                        <br />

                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsConditions;